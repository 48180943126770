@import '../../styles/global.scss';

.certificates {
  @extend .card-base;

  padding: 30px 20px 30px 16px;
  h1 {
    @extend .title;
    font-size: $textSizeLG;
  }
  button {
    margin: 0 auto;
  }
  .list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 40px 0;
    .certificate{
      @extend .card-base;
      background-color: #FCFDFE;
      color: black;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 15px;
      max-width: 160px;
      padding: 20px 30px;
      text-align: center;
      width: 100%;
      img {
        width: 80%;
        height: auto;
        margin: 10px auto;
      }
      p {
        font-size: 12px;
      }
    }
    .certificateSelected {
      @extend .certificate;
      border-color: $colorPrimary;
      color: $colorPrimary;
    }
  }
}
