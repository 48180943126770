@import '../../styles/global.scss';

.listCards {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: -30px;
}

.search {
  align-items: center;
  background-color: #ffffff;
  border: 1px #DFE0EB solid;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  min-height: 300px;
  padding: 37px;
  width: 100%;
  h4 {
    color: $colorBlack;
    font-size: $textSizeLG;
    font-family: 'Mulish', sans-serif;
    text-align: center;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 980px)
{
  .search {
    h4 {
      font-size: $textSizeMD
    }
  }
}
