@import '../../styles/global.scss';

.label {
  color: $colorGrey;
  display: block;
  font-weight: 700;
  margin: 15px 50px 24px 0;
  max-width: 320px;
  position: relative;
  user-select: none;

  small {
    float: right;

    a, a:visited, a:active {
      color: $colorGrey;
      text-decoration: none;
    }
  }
  .input {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px $colorGreyText solid;
    color: $colorBlack;
    display: block;
    height: 42px;
    margin-top: 6px;
    padding: 10px 10px 10px 0;
    width: 100% ;
    *::placeholder, *::-webkit-input-placeholder {
      color: $colorGreyText;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $colorGreyText;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: $colorGreyText;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: $colorGreyText;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: $colorGreyText;
    }
  }

}
