
$textSizeXG: 40px;
$textSizeLG: 24px;
$textSizeMD: 16px;
$textSizeSM: 14px;
$textSizeXS: 12px;

$colorBlack: #252733;
$colorGreyDark: #363740;
$colorGrey: #9FA2B4;
$colorGreyText: #A4A6B3;
$colorGreyLight: #E5E5E5;
$colorWhite: #f5f5f9;
$colorPrimary: #D93E8F;
$colorSuccess: #2FF12B;
$colorDanger: #F12B2C;
