@import '../../styles/variables.scss';

.header {
  align-items: center;
  background-color: white;
  display: flex;
  min-height: 60px;

  .home {
    margin: 9px 36px ;
  }
  h1 {
    font-size: $textSizeLG;
    margin-left: 36px;
    font-weight: 700;
  }
  .profile {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: 30px;
    .halo {
      border: 1px $colorGreyLight solid;
      border-radius: 50%;
      padding: 2px;
      margin-left: 15px;
      .thumbnail {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}
@media screen and(max-width: 980px) {
  .header {
    .home {
      margin: 9px 18px ;
      img {
        width: 80%;
      }
    }
    h1 {
      display: none;
    }
  }
}