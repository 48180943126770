@import '../../styles/global.scss';

.login {
  background-color: $colorGreyDark;
  display: block;
  height: 120vh;
  padding-top: 100px;
  position: relative;
  width: 100%;

  .form {
    align-items: center;
    background-color: #fff;
    border: 1px $colorGreyLight solid;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 528px;
    margin: auto;
    max-width: 380px;
    padding: 40px 32px;
    width: 100%;

    form {
      width: 100%;
    }
    .logo {
      margin-bottom: 48px;
      max-width: 190px;
      width: 100%;
    }
    h1 {
      font-size: $textSizeLG;
      margin-bottom: 20px;
      text-align: center;
    }
    .showPassword {
      background-color: transparent;
      border: none;
      cursor: pointer;
      height: 40px;
      position: absolute;
      right: 10px;
      top: 27px;
      width: 40px;
    }
  }
}
