@import '../../styles/global.scss';

.register {
  align-items: center;
  background-color: #ffffff;
  border: 1px #DFE0EB solid;
  border-radius: 8px;
  margin-top: 30px;
  min-height: 300px;
  padding: 37px;
  width: 100%;
  form {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }
  button {
    margin: 10px auto;
  }
  h2 {
    color: $colorBlack;
    font-size: $textSizeLG;
    font-family: 'Mulish', sans-serif;
    margin-bottom: 30px;
    text-align: left;
    width: 100%;
  }
}
