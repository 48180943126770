@import '~@fontsource/mulish';
@import 'variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Mulish', sans-serif;
  background-color: $colorGreyLight;
}
.container {
  display:flex;
  flex-direction: row;
}
.content {
  background-color: #F7F8FC;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  padding: 50px 30px;
  width: calc(100% - 205px);
}

.card-base {
  background-color: #ffffff;
  border: 1px #DFE0EB solid;
  border-radius: 8px;
  padding: 20px 0;
}

.title {
  color: $colorBlack;
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 980px)
{
  .content {
    width: calc(100% - 60px);
    min-height:100vh;
  }
}