@import '../../styles/global.scss';

.workers {
  @extend .card-base;
  padding: 30px 16px;

  h1 {
    @extend .title;
    font-size: $textSizeLG;
  }
  .list {
    margin-left: -16px;
    margin-top: 48px;
    width: calc(100% + 32px);

    .table {
      border-collapse: collapse;
      display: table;
      width: 100%;

      .head {
        border-bottom: 1px solid $colorGreyLight;
        color: $colorGreyText;
        font-size: $textSizeSM;
        text-align: left;
        width: 100%;
      }

      tr {
        border-bottom: 1px solid $colorGreyLight;
        width: 100%;

        &:hover {
          background-color: $colorWhite;
        }
      }
      th {
        display: table-cell;
        line-height: 40px;
      }
      td {
        display: table-cell;
        padding: 26px 0;
      }
      td:first-child, th:first-child {
        padding-left: 26px;
      }
      td:last-child, th:last-child {
        padding-right: 16px;;
      }
    }
  }
  .pagination {
    color: $colorGreyText;
    display: flex;
    font-size: $textSizeXS;
    justify-content: space-between;
    margin-top: 20px;

    .arrows {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: $textSizeMD;
      font-weight: bold;
      margin: 0 10px;
    }
  }

  .right {
    float: right;

    button {
      background-color: transparent;
      border: none;
      color: $colorGreyText;
      font-size: 16px;
      padding: 10px;

    }
  }

  .searchForm {
    margin-left: -16px;
    margin-top: 10px;
    width: calc(100% + 32px);

    form {
      background-color: #F7F8FC;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 0;
      overflow: hidden;
      width: 100%;
      -moz-transition: height .8s ease-out;
      -ms-transition: height .8s ease-out;
      -o-transition: height .8s ease-out;
      -webkit-transition: height .8s ease-out;
      transition: height .8s ease-out;

      label {
        margin: 20px;
      }
      button {
        display: block;
        margin: 22px 30px;
      }
      &.active{
        height: 100px;
        transition: height .8s ease-in;
        -moz-transition: height .8s ease-in;
        -ms-transition: height .8s ease-in;
        -o-transition: height .8s ease-in;
        -webkit-transition: height .8s ease-in;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .table {
    display: block;
    min-width: 700px;
  }
  .list {
    overflow-x: scroll;
    overflow-wrap: normal;
  }
  td:first-child, th:first-child {
    display: block;
    max-width: 300px;
  }
}

@media screen and (max-width: 980px) {
  .searchForm {
    form {
      flex-wrap: wrap !important;
      justify-content: center;
      &.active {
        height: 350px !important;
      }
    }
  }
}
