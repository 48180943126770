@import '../../styles/variables.scss';

.sidebar {
  background-color: $colorGreyDark;
  color: $colorGreyText;
  max-width: 255px;
  height: auto;

  nav {
    padding: 32px;
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      height: 224px;
      li {
        height: 60px;
        padding-top: 20px;
        width: 100%;
        a {
          align-items: center;
          color: $colorGreyText;
          display: flex;
          font-size: $textSizeMD;
          font-weight: 400;
          text-decoration: none;
          height: 100%;
          width: 100%;
          svg {
            margin-right: 20px;
          }
          &.active, &:hover {
            color: $colorWhite;
            font-weight: 900;
          }
        }
      }
    }
  }
}

@media screen and(max-width: 980px) {
  .sidebar nav {
    padding: 20px 0 ;
    li {
      height: 60px;
      width: 60px !important;
      margin: 0 !important;
      a {
        height: 100%;
        width: 100%;
        svg {
          margin: 0 auto !important;
        }
        span {
          display: none;
        }
      }
    }
  }
}