@import '../../styles/global.scss';

.dropDownButton {
  position: relative;
  cursor: pointer;
  .options {
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    position: absolute;
    padding: 10px 20px;
    right: 0;
    white-space: nowrap;
    border: 1px solid #E5E5E5;
    border-top: none;
  }
  a, button {
    color: $colorGreyText;
    text-decoration: none;
    padding: 5px;
    border: none;
    background-color: transparent;
    &:hover, &:visited {
      text-decoration: none;
    }
  }

}

.dropDownButtonCollapsed {
  @extend .dropDownButton;
  .options {
    display: none;
  }
}
