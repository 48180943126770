@import '../../styles/global.scss';

.config {
  @extend .card-base;
  padding: 30px 20px 30px 16px;
  h1 {
    @extend .title;
    font-size: $textSizeLG;
  }
  form {
    padding: 10px;
  }
}
