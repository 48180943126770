@import '../../../styles/global.scss';

.notFound {
  @extend .card-base;
  padding: 30px 20px 50px;
  h1 {
    @extend .title;
    font-size: $textSizeLG;
    margin-bottom: 30px;
    text-align: center;
  }
  .center {
    @extend .center;
    max-width: 500px;
    margin: 0 auto;
    flex-direction: column;
    p {
      margin-top: 20px;
    }
  }
}
