@import '../../styles/global.scss';

.confirmation {
  @extend .card-base;
  padding: 30px 20px 30px 30px;
  h1 {
    @extend .title;
    font-size: $textSizeLG;
    margin-bottom: 16px;
  }
  h3 {
    color: $colorGrey;
    font-size: $textSizeMD;
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    label {
      input {
        width: 300px;
      }
    }
  }

}
