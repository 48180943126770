@import '../../styles/global.scss';

.button {
  align-items: center;
  box-shadow: 0 4px 12px rgba(55, 81, 255, 0.24);
  border: none;
  background: $colorPrimary;
  border-radius: 8px;
  bottom: 0;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  left: 0;
  max-width: 320px;
  right: 0;
  top: 0;
  width: 100%;
  text-align: center;
  text-decoration: none;
}
