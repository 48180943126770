@import '../../styles/global.scss';

.label {
  color: $colorGrey;
  display: block;
  font-weight: 700;
  margin-bottom: 24px;
  max-width: 320px;
  position: relative;
  user-select: none;
  width: 100%;

  small {
    float: right;

    a, a:visited, a:active {
      color: $colorGrey;
      text-decoration: none;
    }
  }
  .input {
    border: 1px $colorGreyLight solid;
    border-radius: 8px;
    color: $colorGreyText;
    display: block;
    height: 42px;
    margin-top: 6px;
    padding: 10px 20px;
    width: 100%;

    &:focus-visible {
      outline: 1px $colorGrey solid;
    }
    &:active {
      border: 1px $colorGrey solid;
    }
    &::placeholder, &::-webkit-input-placeholder {
      color: #a4a6b3;
      font-family: 'Mulish', sans-serif;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #a4a6b3;
      font-family: 'Mulish', sans-serif;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #a4a6b3;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #a4a6b3;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #a4a6b3;
    }
  }
  select {
    -webkit-appearance: none;
    appearance: none;
    z-index: 10;
  }
  .wrapper {
    position: relative;
    &::after {
      content: "▼";
      font-size: 10px;
      top: 16px;
      right: 16px;
      position: absolute;
      z-index: 0;
    }
  }
}
