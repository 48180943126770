@import '../../styles/global.scss';

.badge {
  border-radius: 8px;
  color: white;
  font-size: $textSizeXS;
  height: 24px;
  max-width: 100px;
  padding: 5px 12px;
  text-align: center;
  width: 90%;
}

.badgeSuccess {
  @extend .badge;
  background-color: $colorSuccess;
}
.badgeError {
  @extend .badge;
  background-color: $colorDanger;
}