@import '../../styles/global.scss';


.card {
  @extend .card-base;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  min-height: 130px;
  max-width: 23%;
  width: 100%;
  min-width: 260px;

  h1 {
    @extend .title;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }

  h2 {
    color: $colorGreyText;
    font-size: $textSizeLG;
    font-family: 'Mulish', sans-serif;
    text-align: center;
  }
}
@media screen and (max-width: 980px)
{
  .card {
      min-width: 240px;
      min-height: 90px;
    h1 {
      font-size: 30px;
      line-height: 35px;
    }
    h2 {
      font-size: $textSizeMD;
    }
  }
}